import Cookies from 'js-cookie';

/** Callsign anomaly detection configuration information
 * essx	- Intelligence base URL	- https://mycompany.callsign.com
 * esto	- Organization ID - "org-1234-1111-2222-3333-4444"
 * ggow - Transaction/ Journey ID - "txn-1234-1111-2222-3333-4444"
 * mosc - Session ID - "ssn-1234-1111-2222-3333-4444"
 * mwel - Journey type - "Login"
 * mwelsub - Subpage type - "UNP"
 * mwelseq - Subpage numerical ID 1
 * sanf - Journey trigger action - "{\"#my-user-form\": \"submit\"}"
 * loco - Location detection enabled? - true (false by default)
 * ewps - Port scanning enabled? - true (false by default)
 * time - Configuration timestamp - Date.now()
 * ccsp - CSP flag - true (false by default)
 * reed - Automatic processing - true (false by default)
 * eeaf - Audio fingerprinting enabled - true (false by default)
 * upfr - Frequent updates flag. (false by default)
 */

const WEB_SDK_URL =
    'https://app.s05.callsign.com/in/web-sdk/v1/static/web-sdk.js';
const INTELLIGENCE_BASE_URL = 'https://app.s05.callsign.com';
const AD_SESSION_ID = 'adSessionId';
const AD_JOURNEY_ID = 'adJourneyId';
let sdkLoadFailed = false;
let organizationId;
let enabledJourneyList;
let activeJourneyType;
let mobileSessionId;
let mobileMwelsub;
const timeBasedTriggerTimer = {
    wait: 600,
    fallback: 2000,
};

const JourneyType = {
    Login: 'PrimaryLogin',
    Transfer: 'MakePayment',
    MySettings: 'ChangeDetails',
    AddRecipient: 'AddPayee',
};

const SubpageType = {
    Login: 'UNP',
    Transfer: 'INTERNALTRANSFER',
    MySettings: 'WEBPROFILE',
    AddRecipient: 'DOMESTIC',
};
function setActiveJourneyType(activeJourney) {
    activeJourneyType = activeJourney;
}
function getActiveJourneyType() {
    return activeJourneyType;
}

function getDBKSessionInfo() {
    const { sessionInfo } = window.dbk || {};

    return sessionInfo?.();
}
// temporary code until BB callsign integration implementation is added
function isBBUserFlow() {
    const { isBBUser } = getDBKSessionInfo() || {};

    return isBBUser;
}

// temporary code until CMA callsign integration implementation is added
function isCMAFlowEnabled() {
    const { isMobileFlow } = getDBKSessionInfo() || {};

    if (isMobileFlow && mobileSessionId && mobileMwelsub) {
        return true;
    }

    return false;
}

function preserveSessionId(sessionId) {
    if (sessionId) {
        sessionStorage.setItem(AD_SESSION_ID, sessionId);
    }
}

function preserveJourneyId(journeyId) {
    if (journeyId) {
        sessionStorage.setItem(AD_JOURNEY_ID, journeyId);
    }
}
function generateAndPreserveSessionId() {
    const sessionId = crypto.randomUUID();
    preserveSessionId(sessionId);
}

function retrieveSessionIdFromCookie() {
    return Cookies.get(AD_SESSION_ID);
}

function retrieveJourneyIdFromCookie() {
    return Cookies.get(AD_JOURNEY_ID);
}

function getSessionId() {
    let sessionId = sessionStorage.getItem(AD_SESSION_ID);

    if (!sessionId) {
        sessionId = retrieveSessionIdFromCookie() ?? crypto.randomUUID();
        preserveSessionId(sessionId);
    }

    return sessionId;
}
function getSessionIdForMobileSSO() {
    sessionStorage.setItem(AD_SESSION_ID, mobileSessionId);

    return mobileSessionId;
}

function getJourneyId() {
    const journeyId = crypto.randomUUID();
    preserveJourneyId(journeyId);

    return journeyId;
}

function preserveConfiguration(adConfig) {
    sessionStorage.setItem('cx', JSON.stringify(adConfig));
}

function buildConfiguration(config) {
    const {
        journeyType,
        subpageType,
        subpageNumericalId,
        isPortScanningEnabled,
        trigger,
    } = config;

    return {
        essx: INTELLIGENCE_BASE_URL,
        esto: organizationId,
        mosc: isCMAFlowEnabled(config)
            ? getSessionIdForMobileSSO()
            : getSessionId(),
        ggow: getJourneyId(),
        mwel: journeyType,
        mwelsub: isCMAFlowEnabled(config) ? mobileMwelsub : subpageType,
        mwelseq: subpageNumericalId ?? 1,
        loco: true,
        ewps: isPortScanningEnabled ?? false,
        reed: false,
        sanf: JSON.stringify(trigger),
        eeaf: false,
        time: Date.now(),
        ccsp: false,
        upfr: true,
    };
}

function isJourneyEnabled(journey) {
    const { isMobileFlow } = getDBKSessionInfo() || {};

    if (isMobileFlow) {
        return isCMAFlowEnabled();
    }

    return enabledJourneyList && journey
        ? enabledJourneyList.toLowerCase().includes(journey?.toLowerCase())
        : false;
}

const head = document.getElementsByTagName('head')[0];
const script = document.createElement('script');

function loadWebSDK({
    orgId,
    journeyList,
    sessionId,
    mwelsub,
    timeBasedTriggerWait,
    timeBasedTriggerFallback,
}) {
    return new Promise((resolve, reject) => {
        script.type = 'text/javascript';
        script.src = WEB_SDK_URL;

        organizationId = orgId;
        enabledJourneyList = journeyList;
        mobileSessionId = sessionId;
        mobileMwelsub = mwelsub;
        script.onload = () => {
            // eslint-disable-next-line no-unused-vars
            const callSignSessionId = getSessionId();

            if (timeBasedTriggerWait && timeBasedTriggerFallback) {
                timeBasedTriggerTimer.wait = timeBasedTriggerWait;
                timeBasedTriggerTimer.fallback = timeBasedTriggerFallback;
            }

            resolve();
        };
        script.onerror = () => {
            sdkLoadFailed = true;

            reject();
        };
        head.appendChild(script);
    });
}

function startJourney(config) {
    // Callsign integration is disabled for CMA flow and BB users temporarily
    if (isBBUserFlow()) {
        return;
    }

    if (sdkLoadFailed) {
        return;
    }

    if (!config) {
        console.log(
            `Anomaly detection configuration is missing for the journey ${config.journeyType}.`,
        );

        return;
    }

    if (!isJourneyEnabled(config.journeyType)) {
        console.log(
            `Anomaly detection journey ${config.journeyType} is not enabled.`,
        );

        return;
    }

    try {
        const adConfig = buildConfiguration(config);
        setActiveJourneyType(config.journeyType);
        preserveConfiguration(adConfig);
    } catch (error) {
        console.error(
            `Error while starting the anomaly detection journey ${config.journeyType}.`,
            error,
        );
    }
}

function stopJourney() {
    sessionStorage.removeItem('cx');
    setActiveJourneyType(undefined);
}

function flushStorage() {
    sessionStorage.removeItem('cx');
    sessionStorage.removeItem('adSessionId');
    const { sessionInfo } = window.dbk || {};

    if (sessionInfo?.dbk) {
        sessionInfo.dbk.adSessionId = undefined;
        sessionInfo.dbk.adJourneyId = undefined;
    }
}

function updateConfigurationTimestamp() {
    const config = sessionStorage.getItem('cx');

    if (config) {
        const serializedConfig = JSON.parse(config);
        serializedConfig.time = Date.now();
        preserveConfiguration(serializedConfig);
    }
}

function getADRequestHeaders() {
    const adSessionId =
        sessionStorage.getItem(AD_SESSION_ID) ?? retrieveSessionIdFromCookie();
    const adJourneyId =
        sessionStorage.getItem(AD_JOURNEY_ID) ?? retrieveJourneyIdFromCookie();

    if (adSessionId && adJourneyId) {
        return { adSessionId, adJourneyId };
    }

    return {};
}

function getTimeBasedTriggerTimer() {
    return timeBasedTriggerTimer;
}
function isCallsignEnabled(callsignConfig, mobileFlow) {
    if (callsignConfig) {
        return (
            callsignConfig.enabled || (mobileFlow && callsignConfig.sessionId)
        );
    }

    return false;
}

export {
    JourneyType,
    SubpageType,
    startJourney,
    loadWebSDK,
    stopJourney,
    generateAndPreserveSessionId,
    flushStorage,
    getDBKSessionInfo,
    getADRequestHeaders,
    getTimeBasedTriggerTimer,
    isCMAFlowEnabled,
    getActiveJourneyType,
    retrieveSessionIdFromCookie,
    retrieveJourneyIdFromCookie,
    updateConfigurationTimestamp,
    isCallsignEnabled,
    WEB_SDK_URL
};
